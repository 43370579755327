import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import emailjs from '@emailjs/browser';
import LogoText from '../assets/logo_text.png';
import BetaLabel from '../assets/beta_label.png';
import styled from 'styled-components';

export default function LogIn({ setIsVerified }) {
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [email, setEmail] = useState(null);

    const [showWarning, setShowWarning] = useState(false);
    const [showEmailSent, setShowEmailSent] = useState(false);

    const userNameChange = (userNameEvent) => {
        setUserName(userNameEvent.target.value);
    }

    const passwordChange = (passwordEvent) => {
        setPassword(passwordEvent.target.value);
    }

    const emailChange = (emailEvent) => {
        setEmail(emailEvent.target.value);
    }

    const checkPw = (e) => {
        // Verify the username and password
        if ((userName === "climate2025" || userName === "test") && password === "noplanetb") {
            setIsVerified(true);
            setShowWarning(false);
        } else {
            setShowWarning(true);
            document.getElementById("username").value = '';
            document.getElementById("password").value = '';
        }

        // Prevent the form re-loading the page
        e.preventDefault();
    }

    const sendEmail = (e) => {
        e.preventDefault();

        var emailParams = {
            email: email
        };

        emailjs.send('service_uquk7r6', 'template_yhq576f', emailParams, 'zoV7e7-tfHsfC2-rM')
            .then(
                (result) => {
                    setShowEmailSent(true);
                },
                (error) => {
                    console.log(error.text);
                });
    };

    return (

        <form onSubmit={checkPw}>
            <Container fluid>
                <LogInContainer className="d-flex flex-column justify-content-center align-items-center">
                    <div className="sign-in-container">
                        <div className="form-body">
                            <div className="login-header d-flex flex-column align-items-center">
                                <div className="d-flex beta-logo">
                                    <img
                                        className="login-logo"
                                        src={LogoText}
                                        alt="Ecosystem Map logo"
                                    ></img>
                                    <img
                                        className="beta-label"
                                        src={BetaLabel}
                                        alt="Beta tag"
                                    ></img>
                                </div>
                                <h3>Welcome to the beta version of the Ecosystem Map</h3>
                                <h5 className="instruction">Please sign in</h5>
                            </div>

                            <div className="login-body d-flex flex-column align-items-center">
                                <div className="login-input">
                                    <Row>
                                        <Col className="d-flex justify-content-end align-items-center">
                                            <div className="input-label">Username</div>
                                            <input id="username" onChange={userNameChange} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-end align-items-center">
                                            <div className="input-label">Password</div>
                                            <input type="password" id="password" onChange={passwordChange} />

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {showWarning ?
                                (<div className="login-warning d-flex justify-content-center">
                                    Wrong user or password.
                                </div>) :
                                (<div></div>)
                            }
                            <div className="login-button d-flex justify-content-center">
                                <button>Sign in</button>
                            </div>
                        </div>
                    </div>

                    <div className="sign-up-container d-flex flex-column align-items-center">
                        <h5>First time here? Become a beta tester of the Ecosystem Map</h5>
                        <div className="input-label-container d-flex align-items-center">
                            <div className="input-label">Enter your email address:</div>
                            <input id="email" onChange={emailChange}></input>
                        </div>
                        {showEmailSent ?
                            (<div className="login-warning d-flex justify-content-center">
                                You have successfully signed up. We will contact you as soon as possible.
                            </div>) :
                            (<div></div>)
                        }
                        <div className="login-button">
                            <button onClick={sendEmail}>Sign up</button>
                        </div>
                    </div>
                </LogInContainer>
            </Container>
        </form>

    )
}

const LogInContainer = styled.div`
    height: 100vh;

    .form-body{
        margin-top: -10vh;
    }

    .sign-up-container {
        margin-top: 40px;
    }

    .login-logo {
        height: 50px;
    }

    .beta-label {
        display: inline;
        transform: rotate(0.2rad);
        height: calc(50px * 0.6);
        margin-top: calc(50px * -0.6);
        margin-left: calc(50px * -0.7);
    }

    .login-header {
        text-align: center;
    
        img {
            margin-bottom: 10px;
        }

        h3 {
            margin-bottom: 20px;
        }

        h5 {
            margin: 0;
        }
    }

    .login-input {
        margin: 10px 60px 6px 0;
    }

    input {
        margin: 5px 0;
        border-radius: 2px;
        border: 1px solid var(--primary-color-1);
    }

    .input-label {
        padding-right: 10px;
    }

    .input-label-container {
        margin: 2px 0 6px 0;
    }

    .login-warning {
        font-style: italic;
    }

    .login-button {
        margin-top: 10px;
        
        button {
            background-color: white;
            border-radius: 5px;
            padding: 7px 14px;
            
            border: 1px solid var(--primary-color-1);
            
            font-size: 15px;
            font-weight: 500;
            color: var(--primary-color-1);
        }
    }

    .login-button button:hover {
        background-color: var(--primary-color-1);
        color: white;
    }

    /* Small screens */
    @media (max-width: 400px) {
        .login-logo {
            width: 250px;
            height: auto;
        }
    }
`
